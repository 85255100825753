// app

(function($) {

    'use strict';

    window.App = {

        // Define the selectors
        window: $(window),
        body: $('body'),
        html: $('html'),
        page: $('.page'),
        accordionSelector: '.accordion',
        accordionTitleLinkSelector: '.accordion .header a',
        hasDropDownSelector: '.has-dropdown',
        mainNavigationSingleRowSelector: '.main-menu-single-row .main-navigation',
        mainNavigationSelector: '.main-navigation',
        mobileMenuSelector: '.mobile-menu',
        sliderSelector: '.swiper-container',
        subNavigationSelector: '.sub-navigation',
        // tabsSelector: '.tabs',
        toggleMobileSearchSelector: '.toggle-mobile-search',
        fixedHeaderClass: 'fixed-header',
        subNavigationLoaded: false,
        mainNavigationLoaded: true,
        inCrossOriginFrame: false,

        /**
         * Initialise the object
         */
        init: function() {

            var self = this;
            var editmode = self.body.hasClass('edit-mode');
            
            //checking if we run in the mobile app, this limits some HCL code
            try { top.location.href } catch (e) {
              if (e.message.indexOf('cross-origin') > 0) {
                self.inCrossOriginFrame = true;
              }
            }
                        
            if (self.inCrossOriginFrame) { //when running in mobileapp, propagate beforeunload event for wait indicator
                 $('body').on('click', 'a', function(e) {
                     var href = e.currentTarget.getAttribute('href');
                     var target = e.currentTarget.getAttribute('target');
                     if (target == '') {
                       target = null;
                     }
                     if ( target == null && href != null && href.indexOf('#') != 0) {
                       top.postMessage({ type: "frame-event" }, "*");
                     }
                 });
            }            

            if (!editmode) {
              // Initialize mobile menu
              $(this.mobileMenuSelector).mmenu();
            }

            // Initialize accordion
            $(this.accordionSelector).accordion({
                'transitionSpeed': 300
            });


            // Initialize slider
            $(this.sliderSelector).each(function() {
                new self.Slider($(this));
            });

            // Initialize popups
            this.Popup.init();
            this.mobileMenu();
            this.bind();
            this.videoThumbnails();

            $(window).on('scroll', function() {
                var position = $(window).scrollTop();
                var menu = $('.main-menu-single-row');
                var menuHeight = menu.outerHeight();
                if (position > menuHeight) {
                    menu.addClass('fixed');
                    $('body').addClass('mobile-margin');
                } else {
                    menu.removeClass('fixed');
                    $('body').removeClass('mobile-margin');
                }
            });
        },

        isEditModeActive: function isEditModeActive() {
          var self = this;
          if (self.inCrossOriginFrame) {
            return false;
          }
          if (typeof wpModules == 'undefined') {
            return false;
          }
          return wpModules.toolbar.isEditModeActive();
        },

        /**
         * Set up event handlers
         */
        bind: function() {

            var self = this;

            if (!self.body.hasClass('indialog')) {

                // Initialize mobile search toggle
                $(this.toggleMobileSearchSelector).on('click', function(event) {
                    self.html.toggleClass('show-search');
                    event.preventDefault();
                });

                // Initialize fixed header
                if (!self.isEditModeActive()) {
                    this.window.scroll(function() {
                        if ( ($(self.mainNavigationSelector).length && self.window.scrollTop() > $(self.mainNavigationSelector).height()) || 
                             ($(self.mainNavigationSingleRowSelector).length && self.window.scrollTop() > $(self.mainNavigationSingleRowSelector).height())
                        ) {
                            self.html.addClass(self.fixedHeaderClass);
                        } else {
                            self.html.removeClass(self.fixedHeaderClass);
                        }
                    });
                }


                // Initialize sub navigation
                if ($(this.subNavigationSelector).length) {
                    self.updateSubNavigation();
                    this.window.on('resize', function() {
                        self.updateSubNavigation();
                    });
                }

                // Initialize main navigation
                if ($(this.mainNavigationSelector).length) {
                    self.updateMainNavigation(); // on first load
                    this.window.on('resize', function() {
                        self.updateMainNavigation();
                    });
                }

                // Prevents following the more link in the sub navigation
                $(this.subNavigationSelector).find('.more').on('click', function(event) {
                    if (!($(event.target).closest('.drop-down').length)) {
                        event.preventDefault();
                    }
                });
            }

            // Initialize drop downs
            $(this.hasDropDownSelector).each(function() {
                var el = $(this),
                    link = el.find('> a'),
                    href = link.attr('href'),
                    touchEnabled = false;

                el.on('focusin', function() {
                    el.addClass('open');
                }).on('focusout mouseout', function() {
                    el.removeClass('open');
                    touchEnabled = false;
                });

                link.on('touchstart', function() {
                    if(touchEnabled) {
                        window.location = href;
                    } else {
                        touchEnabled = true;
                    }
                }).on('click', function(event) {
                    if(touchEnabled) {
                        event.preventDefault();
                    }
                });
			});

            $(this.accordionSelector).on('click', '.header > .inside', function(e) {
                var target = $(e.target),
                pageOffset = $(document).scrollTop();
                    setTimeout(function() {
                        var offsetTarget = target.offset().top;
                        $('html, body').animate({
                        scrollTop: (offsetTarget-80)
                    }, 300);
                }, 350);
            });

            // Prevents following the header link of an accordion
            $(this.accordionTitleLinkSelector).on('click', function(event) {
                event.preventDefault();
            });

        },

        /**
         * Initialize sub navigation
         */
        updateSubNavigation: function() {

            $(this.subNavigationSelector).children().each(function() {

                var nav = $(this).find('nav'),
                    navWidth = nav.width(),
                    list = nav.find('> ul'),
                    more = nav.find('.more'),
                    moreWidth = Math.round(more.outerWidth(true)) + 20,
                    availableWidth = 0,
                    showMoreButton = false;

                list.children('li').not(more).each(function(index) {
                    var el = $(this);
                    availableWidth += Math.round(el.outerWidth(true));
                    if(availableWidth < (navWidth - moreWidth)) {
                        el.show();
                        more.find('li').eq(index).hide();
                    } else {
                        showMoreButton = true;
                        el.hide();
                        more.find('li').eq(index).show();
                    }
                });

                showMoreButton ? more.addClass('show') : more.removeClass('show');
            });

            if(!this.subNavigationLoaded) {
                $(this.subNavigationSelector).find('nav').addClass('loaded');
                this.subNavigationLoaded = true;
            }
        },
        updateMainNavigation: function() {

            var nav = $(this.mainNavigationSingleRowSelector).find('nav'),
                navWidth = nav.width(),
                list = nav.find('> ul'),
                more = nav.find('.more'),
                moreWidth = Math.round(more.outerWidth(true)) + 20,
                availableWidth = 0,
                showMoreButton = false;

            list.children('li').not(more).each(function(index) {
                var el = $(this);
                availableWidth += Math.round(el.outerWidth(true));
                if(availableWidth < (navWidth - moreWidth)) {
                    el.show();
                    more.find('li').eq(index).hide();
                } else {
                    showMoreButton = true;
                    el.hide();
                    more.find('li').eq(index).show();
                }
            });

            showMoreButton ? more.addClass('show') : more.removeClass('show');


            if(!this.subNavigationLoaded) {
                $(this.mainNavigationSelector).find('nav').addClass('loaded');
                this.subNavigationLoaded = true;
            }
        },

        mobileMenu: function() {
            console.log('mobile menu');
            $('.main-menu-single-row .menu-button > a').on('click', function(e) {
                e.preventDefault();
                e.stopPropagation();
                if (!$('html').hasClass('mobile-opened')) {
                    $('html').addClass('mobile-blocking mobile-opening mobile-opened');
                    $('.mobile-menu-portal').addClass('mobile-opened');
                }
            });

            $('#mobile-blocker, .flexible-close, .mobile-menu-portal .menu-close').on('click', function(e) {
                if ($('html').hasClass('mobile-opened')) {
                    $('html').removeClass('mobile-blocking mobile-opening mobile-opened');
                    setTimeout(function() {
                        $('.mobile-menu-portal').removeClass('mobile-opened');
                    }, 300);
                }
            });
            $('.mobile-menu-portal .nested > .icon-nested').add('.mobile-menu-portal .nested > a').on('click', function(e) {
                console.log('click');
                e.preventDefault();
                e.stopPropagation();
                var parent = $(this).parent();
                if (parent.hasClass('nested-open')) {
                    parent.removeClass('nested-open');
                    parent.attr('aria-expanded', 'false')
                } else {
                    parent.addClass('nested-open');
                    parent.attr('aria-expanded', 'true');
                }
            });
        },

        Custompopup: function() {
            var popup = $('.popup_holder');
            if(popup.length) {
                var visible = popup.css('display');
                if (visible == 'block') {
                    console.log('add class no-scroll');
                    $('html').addClass('no-scroll');
                } else {
                    console.log('remove class no-scroll');
                    $('html').removeClass('no-scroll');
                }
                popup.on('click', function(e) {
                    e.preventDefault();
                    console.log('on click .popup_holder');
                    var visible = popup.css('display');
                    if (visible == 'none') {
                            $('html').removeClass('no-scroll');
                    }
                });
            }
        },

        videoThumbnails: function() {
            $('figure.video a.video-popup').each(function() {
                let $videoPopup = $(this);
                if ($('fun-add-background-image', $videoPopup).length == 0) {
                    let matchVimeo = $videoPopup.attr('href').match(/.*\.vimeo\.com\/video\/(.*)/);
                    let thumbnailWidth = $videoPopup.width();
                    if (matchVimeo) {
                        let videoId = matchVimeo[1];
                        let url = `/app/vimeo/data?id=${videoId}&selector=image&width=${thumbnailWidth}`
                        $.getJSON(url, function( data ) {
                            $('img', $videoPopup).css('background-image',"url(" +  data['link'] + ")");
                        })
                    } else {
                        let matchYoutube = $videoPopup.attr('href').match(/.*youtu.*[\/|=](.*)/);
                        if (matchYoutube) {
                            let thumbnailUrl = '//i.ytimg.com/vi/' + matchYoutube[1] + '/' + App.toThumbnailWidth(thumbnailWidth) + '.jpg';
                            $('img', $videoPopup).css('background-image',"url(" +  thumbnailUrl + ")");
                        }
                    }
                }
            })
        },

        toThumbnailWidth: function(width) {
            if (width > 320) {
                return "hqdefault"; //w 480
            } else {
                return "mqdefault"; //w 320
            }
        }
    };

}(jQuery));

// app.popup

(function ($) {

    'use strict';

    /**
     * Popup object
     */

    App.Popup = {

        gallerySelector: '.gallery-popup',
        imageSelector: '.image-popup',
        imageGallerySelector: '.image-popup-gallery',
        videoSelector: '.video-popup:not(.loop)',
        videoLoopSelector: '.video-popup.loop',
        nextLabel: 'Volgende',
        previousLabel: 'Vorige',
        inCrossOriginFrame: false,

        imagePopupOptions: {
            closeMarkup: '<a title="%title%" class="mfp-close"><span>Sluiten</span></a>',
            image: {
                titleSrc: function titleSrc(item) {
                    return item.el.find('img').attr('alt');
                }
            },
            type: 'image',
            zoom: {
                easing: 'ease-in-out',
                enabled: true,
                duration: 200
            }
        },

        videoPopupOptions: {
        	disableOn: 767,
            type: 'iframe',
            zoom: {
                easing: 'ease-in-out',
                enabled: true,
                duration: 20000
            }
        },

        videoPopupLoopOptions: {
        	disableOn: 767,
            type: 'iframe',
            zoom: {
                easing: 'ease-in-out',
                enabled: true,
                duration: 20000
            },
            iframe: {
				patterns: {
					    youtube: {
			                index: 'youtube.com',
			                id: 'v=',
			                src: '//www.youtube.com/embed/%id%?autoplay=1&playlist=%id%&loop=1'
			            },
			            vimeo: {
			                index: 'vimeo.com/',
			                id: '/',
			                src: '//player.vimeo.com/video/%id%?autoplay=1'
					    }
				}
			}
        },

        /**
         * Initialise the object
         */
        init: function init() {
            this.bind();
        },

        /**
         * Set up event handlers
         */
        bind: function bind() {
            var self = this;
            
             //checking if we run in the mobile app, this limits some HCL code
            try { top.location.href } catch (e) {
              if (e.message.indexOf('cross-origin') > 0) {
                self.inCrossOriginFrame = true;
              }
            }

            // Image popup
            $(this.imageSelector).magnificPopup(this.imagePopupOptions);

            // Gallery popup
            $(this.gallerySelector).each(function () {
                $(this).magnificPopup($.extend({
                    delegate: self.imageGallerySelector,
                    gallery: {
                        arrowMarkup: '<button title="%title%" type="button" class="dd mfp-arrow mfp-arrow-%dir%"></button>',
                        enabled: true,
                        tCounter: '<span class="mfp-counter">%curr% / %total%</span>',
                        tPrev: self.previousLabel,
                        tNext: self.nextLabel
                    }
                }, self.imagePopupOptions));
            });
            
            if (self.inCrossOriginFrame) {
                $(this.videoSelector).attr('target', '_blank');
                $(this.videoLoopSelector).attr('target', '_blank');
                $(document).on('click', 'a[target=_blank]' , function(e) {
                    e.preventDefault(); // Prevent the default behavior of the link
                    let linkUrl = $(this).attr('href');
                    top.postMessage({ type: "open-link", href: linkUrl, target: '_blank' }, "*");
                });         
            } else {
                $(this.videoSelector).magnificPopup(this.videoPopupOptions);
                $(this.videoLoopSelector).magnificPopup(this.videoPopupLoopOptions);
            }
                
        }
    };
})(jQuery);

// app.accordion
(function () {
    'use strict';
    App.Accordion = function (container, options) {
        this.opts = $.extend(this.defaults, options);
        this.container = container;
        this.initAccordion();
    };

    App.Accordion.prototype.initAccordion = function () {

        $('.item').each(function () {

            var el = $(this),
                header = el.find('.header'),
                content = el.find('.content');

            header.on('click', function (event) {

                if (el.hasClass('open')) {
                    el.removeClass('open');
                    content.slideUp(200);
                } else {
                    el.addClass('open');
                    content.slideDown(200);
                }

                event.preventDefault();

            });
        });
    };

})();

// app.slider
(function () {

    'use strict';

    App.Slider = function (container, options) {
        this.opts = $.extend(this.defaults, options);
        this.container = container;
        this.slides = this.container.find(this.opts.slideSelector);

        if (this.slides.length > 1) {
            this.initSlider();
        }
    };

    App.Slider.prototype.defaults = {
        autoplay: 6000,
        effect: 'fade',
        nextButton: '.swiper-button-next',
        pagination: '.swiper-pagination',
        paginationClickable: true,
        prevButton: '.swiper-button-prev',
        slideSelector: '.swiper-slide',
        spaceBetween: 0,
        speed: 400
    };

    App.Slider.prototype.initSlider = function () {
        new Swiper(this.container, this.opts);
    };
})();

var afspraakWijzigen = {};

$(document).ready(function() {
	App.Popup.init();
	App.init();
});
